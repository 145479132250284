<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>전자결재</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap">
                <CarrotTitle title="기안작성">
                    <div class="btn-ibbox title-btnbox float-right">
                        <button class="btn-default">양식선택</button>
                        <button class="btn-default ml-10">불러오기</button>
                        <button class="btn-default ml-10">결재선</button>
                    </div>
                </CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>

                            <div class="dp-table w-100per">
                                <div class="float-left w-50per pr-25"> 
                                    <table class="table-row">
                                        <tbody>
                                            <tr>
                                                <th width="150">기안자</th>
                                                <td>Min K (김민정)</td>
                                            </tr>
                                            <tr>
                                                <th width="150">소속</th>
                                                <td>솔루션개발팀</td>
                                            </tr>
                                            <tr>
                                                <th width="150">작성일</th>
                                                <td>2019-00-00</td>
                                            </tr>
                                            <tr>
                                                <th width="150">문서번호</th>
                                                <td>20190000ASD</td>
                                            </tr>
                                            <tr>
                                                <th width="150">보안</th>
                                                <td>
                                                    <label><input type="checkbox" id="Private" name="Private" value="Private"><span class="ml-5"> Private</span></label>
                                                    <div class="mt-5 color-1">
                                                        * 급여성, 인사관련사항 결재 시 체크해주세요.<br>
                                                        * Private 체크 시 회계팀 Ahri (이은주) 참조해 주세요.
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="float-right w-50per pl-25">
                                    <table class="table-col">
                                        <colgroup>
                                            <col span="5"><!--결재지정한 수만큼(thead th수)-->
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>담당</th>
                                                <th>Staff</th>
                                                <th>Staff</th>
                                                <th>팀장</th>
                                                <th>관리자</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Helen</td>
                                                <td>Helen</td>
                                                <td>Helen</td>
                                                <td>Helen</td>
                                                <td>Helen</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table class="table-row mt-45">
                                        <tbody>
                                            <tr>
                                                <th width="110" rowspan="4">참조</th>
                                                <td>솔루션개발팀</td>
                                                <td>Sophie J(정수빈)</td>
                                                <td>미확인</td>
                                            </tr>
                                            <tr>
                                                <td>솔루션개발팀</td>
                                                <td>Sophie J(정수빈)</td>
                                                <td>01/29</td>
                                            </tr>
                                            <tr>
                                                <td>솔루션개발팀</td>
                                                <td>Sophie J(정수빈)</td>
                                                <td>미확인</td>
                                            </tr>
                                            <tr>
                                                <td>솔루션개발팀</td>
                                                <td>Sophie J(정수빈)</td>
                                                <td>미확인</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <table class="table-row mt-45">
                                <tbody>
                                    <tr>
                                        <th width="150">제목</th>
                                        <td>
                                            <input type="text" class="w-100per">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table-row mt-10">
                                <tbody>
                                    <tr>
                                        <th width="150"><span class="txt-red">*</span> 구분</th>
                                        <td>
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 연차</span></label>
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 오전 반차</span></label>
                                            <label><input type="radio" id="" name="" value=""><span class="ml-5 mr-20"> 오후 반차</span></label>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="150"><span class="txt-red">*</span> 기간</th>
                                        <td>
                                            <input type="text" class="w-80px"> ~
                                            <input type="text" class="w-80px">
                                            <div class="color-1">
                                                ※ 법정공휴일 또는 주말은 자동 제외됩니다.<br>
                                                ※ 불특정 공휴일(대체공휴일 등)은 자동 제외되지 않으니 유의하시기 바랍니다.
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th width="150"><span class="txt-red">*</span> 연차로 인한 <br>업무 조정 내용</th>
                                        <td>
                                            <textarea class="w-100per h-100px"></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <router-link to=""><button class="btn-default float-left mt-30">취소</button></router-link>
                            <router-link to=""><button class="btn-default float-right mt-30">등록</button></router-link>
                            <div class="clear"></div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src 


export default {
}
</script>

<style lang="scss" scoped>
</style>